import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect } from 'react'

import SessionTimeout from '../../assets/images/session-timeout.svg'
import { Layout } from '../../components/auth/Layout'
import { SimpleContent } from '../../components/auth/SimpleContent'
import { useScopedIntl } from '../../hooks'
import { AccountType, fetchApi } from '../../requests'
import { routes } from '../../routes'

const SessionTimeoutPage = () => {
  const intlTimeout = useScopedIntl('auth.session_timeout')

  useEffect(() => {
    fetchApi.signOut({
      onSuccess: () => null,
      onRequestError: () => null
    })
  }, [])

  const onSubmit = () => {
    navigate(routes.signIn(AccountType.User))
    return Promise.resolve()
  }

  return (
    <Layout accountType={AccountType.User}>
      <SimpleContent
        title={intlTimeout('title')}
        subtitle={intlTimeout('subtitle')}
        submit={intlTimeout('login')}
        Image={SessionTimeout}
        onSubmit={onSubmit}
      />
    </Layout>
  )
}

export default SessionTimeoutPage
export const Head = () => <title>Datacapt - Session timeout</title>
