import './SimpleContent.less'

import React from 'react'

import { useSubmit } from '../../../hooks'
import { DatacSubtitle, DatacTitle } from '../../common'
import { FormSubmitButton } from '../FormSubmitButton'

interface Props {
  title: string
  subtitle: string
  message?: string
  submit: string
  onSubmit: () => Promise<void>
  // eslint-disable-next-line
  Image: any
  showSubmit?: boolean
}

export const SimpleContent: React.FC<Props> = ({
  title,
  subtitle,
  message,
  submit,
  onSubmit,
  Image,
  showSubmit = true
}) => {
  const [isSubmitOngoing, onSubmitCallback] = useSubmit<void>(onSubmit)

  return (
    <div className="simple-content">
      <DatacTitle type="h1" className="simple-content__title">
        {title}
      </DatacTitle>
      <DatacSubtitle type="h2" className="simple-content__subtitle">
        {subtitle}
      </DatacSubtitle>
      <Image className="simple-content__image" />
      <div>{message}</div>
      {showSubmit && (
        <FormSubmitButton
          label={submit}
          onClick={onSubmitCallback}
          className="simple-content__submit"
          loading={isSubmitOngoing}
        />
      )}
    </div>
  )
}
